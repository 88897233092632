import React, { Component } from 'react';
import { isEmpty, isEqual } from 'lodash';
import DateUtilities from './utils';
import { dateTimeFormat, formatIso } from './dateUtils';
import { withStyles } from '@mui/styles';
import Circle from './Circle';
import { Badge, Tooltip } from '@mui/material';
import { orange, errorRed } from '../../css/style';
import { IMG_STORAGE_LINK } from '../constants/Constants';
import { getPriorityPointsInfo } from '../../utils/Functions';
import moment from 'moment/moment';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    //height: 29,
    //marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),

    
  },
  day: {
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#404A59',

    '& > *': {
      fontFamily: 'Asap',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '13px',
      fontWeight: 400,
    },
  },
  blank: {
    width: 36,
    height: 36,
    margin: 0,
  },
  largeBlank: {
    width: '100%',
    height: 32,
  },
  pastDayBtnContainer: {
    backgroundColor: '#d8d8d8',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& .MuiBadge-badge': {
      height: '20px',
      minWidth: '20px',
      borderRadius: '50%',
      fontSize: '8px',
      fontWeight: '300',
    },
  },
  dayBtnContainer: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& .MuiBadge-badge': {
      height: '16px',
      minWidth: '16px',
      borderRadius: '50%',
      fontSize: '8px',
      fontWeight: '300',
    },
  },
  boostBadge: {
    '& .MuiBadge-badge': {
      height: '15px',
      minWidth: '15px',
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: '700',
      backgroundColor: 'white',
      padding: 0,
    },

    '& .MuiBadge-anchorOriginBottomRightCircular': {
      right: '6%',
      bottom: '6%',
      color: errorRed,
    },
    '& .MuiBadge-anchorOriginBottomLeftCircular': {
      left: '6%',
      bottom: '6%'
    },
  },
  priorityPointsBadge: {
    '& .MuiBadge-badge': {
      height: '15px',
      minWidth: '15px',
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: '700',
      backgroundColor: 'white',
      padding: 0,
    },

    '& .MuiBadge-anchorOriginBottomRightCircular': {
      right: '6%',
      bottom: '6%',
      color: orange,
    },
    '& .MuiBadge-anchorOriginBottomLeftCircular': {
      left: '6%',
      bottom: '6%'
    },
  },
});

class Week extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarData: props?.calendarData || [],
    };
  }
  onSelect = (day) => {
    if (!this.isDisabled(day)) this.props.onSelect(day);
  }

  isDisabled = (day) => {
    if (this.props.readOnly) return true;

    let minDate = this.props.minDate;

    let maxDate = this.props.maxDate;

    return (
      this.isPending(day)
      || (minDate && DateUtilities.isBefore(day, minDate))
      || (maxDate && DateUtilities.isAfter(day, maxDate))
    );
  }

  isSelected = day => this.props.selectedDates && DateUtilities.dateIn(this.props.selectedDates, day)

  isRequested = day => this.props.requestedDates && DateUtilities.dateIn(this.props.requestedDates, day)
  
  isConfirmed = day => this.props.confirmedDates && DateUtilities.dateIn(this.props.confirmedDates, day)

  isNotAvailable = day => this.props.notAvailableDates && DateUtilities.dateIn(this.props.notAvailableDates, day)

  isPending = day => !isEmpty(this.props.pendingDates) && DateUtilities.dateIn(this.props.pendingDates, day)

  isHighLighted = day => !isEmpty(this.props.highlightedList) && DateUtilities.dateIn(this.props.highlightedList, day)

  setBoostData = (day) => {
    const { boosted = [], surgeryNeededList } = this.props;
    const { calendarData = [] } = this.state;

    let boostedData = {};
    let surgeryNeeded = 0;
    if (!isEmpty(calendarData)) {
      const boostedRow = calendarData.find(row =>  row.status === 'available' && row.is_boosted && row.date && DateUtilities.isSameDay(DateUtilities.midDayDate(row.date), day));
      if (boostedRow) {
        boostedData = { amount: boostedRow.boost_amount };
      }
      surgeryNeeded = calendarData.filter(row =>  row.status === 'available' && row.surgery_needed && row.date && DateUtilities.isSameDay(DateUtilities.midDayDate(row.date), day)).length;
    } else {
      boostedData = !isEmpty(boosted)
        ? boosted.find(row => row.date && DateUtilities.isSameDay(DateUtilities.midDayDate(row.date), day)) : {};

      surgeryNeeded = !isEmpty(surgeryNeededList)
        ? surgeryNeededList.filter(surDate => DateUtilities.isSameDay(DateUtilities.midDayDate(surDate), day)).length : 0;
    }
    return { boostedData, surgeryNeeded };
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.calendarData, prevState.calendarData)) {
      return { calendarData: nextProps.calendarData };
    }
    return null;
  }

  render() {
    const { classes, largeSize, isPriorityHospital, shiftStartTime } = this.props;

    const dateInNumberic = new dateTimeFormat('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    const dateToday = dateInNumberic.format(new Date());

    const dayInNumeric = new dateTimeFormat('en-US', {
      day: 'numeric',
    });
    return (
      <div className={classes.root}>
        {this.props.week.map((day, i) => {
          if (day) {
            const isToday = day && dateToday === dateInNumberic.format(day);
            const isPastDate = day && (Date.parse(day) < Date.parse(dateToday));
            const isDisabled = this.isDisabled(day);
            const isSelected = this.isSelected(day);
            const isRequested = this.isRequested(day);
            const isConfirmed = this.isConfirmed(day);
            const isNotAvailable = this.isNotAvailable(day);
            const isPending = this.isPending(day);
            const isHighLighted = this.isHighLighted(day);

            const { boostedData, surgeryNeeded } = this.setBoostData(day);

            const showBoosted = (isSelected && !isConfirmed && !isNotAvailable && !isPending) && !isEmpty(boostedData);
            const showPriorityPoints = shiftStartTime && (isSelected || isConfirmed || isNotAvailable || isPending || isHighLighted || isRequested);
            const priorityPointsInfo = getPriorityPointsInfo(moment(`${formatIso(day)} ${shiftStartTime}`, 'YYYY-MM-DD h:mm(A)'), isPriorityHospital);
            return (largeSize
              ? (
              <div
                key={`day_container_${i}`}
                className={isPastDate ? classes.pastDayBtnContainer : classes.dayBtnContainer}
              >
                <Badge
                  key={`boost_icon_${i}`}
                  overlap="circular"
                  badgeContent={showBoosted && (
                    <img
                      src={`${IMG_STORAGE_LINK}dollar.svg`}
                      width='14'
                      alt='boosted' title='boosted'
                    />)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  className={showBoosted && classes.boostBadge}
                >
                <Badge
                  key={`priority_points_icon_${i}`}
                  overlap="circular"
                  badgeContent={showPriorityPoints && (
                    <span>
                      <Tooltip title='Priority Points' placement='bottom' >
                        {priorityPointsInfo?.points}
                      </Tooltip>
                    </span>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  className={showPriorityPoints && classes.priorityPointsBadge}
                >

                  <Circle
                    key={`day-${day}`}
                    label={dayInNumeric.format(day)}
                    checked={isSelected}
                    requested={isRequested}
                    confirmed={isConfirmed}
                    onCheck={(e) => {
                      this.onSelect(day);
                    }}
                    isToday={isToday}
                    className={classes.day}
                    isHighLighted={isHighLighted}
                  />
                </Badge>
              </Badge>
              </div>
              )
              : (
              <div
                key={`day_container_${i}`}
                className={isPastDate ? classes.pastDayBtnContainer : classes.dayBtnContainer}
              >
                <Badge
                  key={`boost_icon_${i}`}
                  overlap="circular"
                  badgeContent={showBoosted && (
                    <img
                      src={`${IMG_STORAGE_LINK}dollar.svg`}
                      width='14'
                      alt='boosted' title='boosted'
                    />)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  className={showBoosted && classes.boostBadge}
                >
               <Badge
                  key={`priority_points_icon_${i}`}
                  overlap="circular"
                  badgeContent={showPriorityPoints && (
                    <span>
                      <Tooltip title='Priority Points' placement='bottom' >
                      {priorityPointsInfo?.points}
                      </Tooltip>
                    </span>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  className={showPriorityPoints && classes.priorityPointsBadge}
                >

                <Circle
                  key={`day-${day}`}
                  label={dayInNumeric.format(day)}
                  disabled={isDisabled}
                  checked={isSelected}
                  requested={isRequested}
                  confirmed={isConfirmed}
                  onCheck={(e) => {
                    this.onSelect(day);
                  }}
                  isNotAvailable={isNotAvailable}
                  isPending={isPending}
                  isToday={isToday}
                  className={classes.day}
                  isHighLighted={isHighLighted}
                  isFastpass={this.props.isFastpass}
                />
                </Badge>
                </Badge>
              </div>
              )
            );
          }
          return <div className={classes.largeBlank} key={`blank-${i}`} />;
        })}
      </div>
    );
  }
}

export default withStyles(styles)(Week);
