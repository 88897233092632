import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { isEmpty, uniqBy, uniq, flatMapDeep, isEqual, sortBy } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import {
  Typography,
  Grid,
  Box,
  Drawer,
  IconButton,
  Fab,
  Breadcrumbs,
  Chip,
  Divider,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material/';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';

import { IMG_STORAGE_LINK, DESKTOP_BREAKPOINT, CORONADO_BADGE_TITLE } from '../constants/Constants';
import types from '../../actions/ActionTypes';
import { navy, orange, iconGray, bgGray, tiffany, lightGray } from '../../css/style';
import { MatchesComponentStyle } from './styles/matchesComponentStyle';
import ConfirmEmail from '../subcomponents/ConfirmEmail';
import MatchesSearchBar from '../subcomponents/MatchesSearchBar';
import DeleteConfirmDialog from '../subcomponents/DeleteConfirmDialog';
import JobMatchCard from '../subcomponents/JobMatchCard';
import JobDetailsDialog from '../subcomponents/JobDetailsDialog';
import CalendarFilter from '../Datepicker/CalendarFilter';
import LocationGoogleMap from '../subcomponents/LocationGoogleMap';
import DateUtilities from '../Datepicker/utils';
import { convertHTMLToText, formatPostTitle, isReliefOrExternshipType } from '../../utils/Functions';
import MatchFilter from './MatchFilter';
import SavedSearchDialog from '../subcomponents/SavedSearchDialog';
import { renderPhoneVerificationModal, renderConnectModal } from '../core/renderHelper';
import { Redirect } from 'react-router';
import ShiftCalendarDialog from '../subcomponents/ShiftCalendarDialog';

const TIMEOUT_DURATION = 400;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MatchesComponent extends Component {
  constructor(props) {
    super(props);
    const { user, resources } = this.props;

    const filterDatesInStr = localStorage.getItem('filterDatesInStr');
    let filteredDateArr = filterDatesInStr ? filterDatesInStr.split(',').map(dateText => DateUtilities.midDayDate(dateText)) : [];

    this.state = {
      openConfirmationModal: false,
      filter: '',
      openSuccessModal: false,
      openDeleteConfirmDialog: false,
      matchToBeDeleted: {},
      isMatchDeleted: false,
      filterDates: filteredDateArr,
      items: [],
      searchTerm: '',
      fastpassData: [],
      openConfirmedShiftsDialog: false,
      calendarDrawerOpen: true,
      searchDrawerOpen: true,
      mapView: false,
      selectPracticeEnabled: user.privateAccess && (user.privateAccess.indexOf('HQ Admin') >= 0 || user.privateAccess.indexOf('Private User') >= 0),
      openSearchDialog: false,
      openMobileCalendar: true,
      openPhoneVerification: false,
      isLoading: false,
      rotationType: '',
      calendarMatches: {
        available: {},
        confirmed: {},
      },
      selectedEvent: {},
    };
  }

  refreshItems = () => {
    const { actions, user, resources } = this.props;
    this.setState({ isLoading: true });
    actions.getAllResources(user.token, 'matches').then(() => {
      this.getCalendarMatches();
      this.setState({ isLoading: false });
    });
    this.refreshStats();
    actions.getAllResources(user.token, 'savedsearchs');

    if (!resources?.practices) {
      actions.getAllResources(user.token, 'practices');
    }

    if (!resources?.talents) {
      actions.getAllResources(user.token, 'talents');
    }
    if (!resources?.talentprofileitems) {
      actions.getAllResources(user.token, 'talentprofileitems');
    }
    if (!resources?.criterias) {
      actions.getAllResources(user.token, 'criterias');
    }

    const filterPracticeId = localStorage.getItem('filterPracticeId') || '';
    const matchBadgeTitle = localStorage.getItem('matchBadgeTitle') || '';
    this.setState({ filterPracticeId, matchBadgeTitle })
  }

  componentDidMount = async () => {
    const { resources } = this.props;
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    this.setState({ calendarMatches });
    this.refreshItems();
    this.retrieveFastpassData();
  }

  componentDidUpdate(prevProps, prevState) {
    const calendarMatches = this.props.resources['jobPostingCalendars/getCurrentCalendarMatches'];
    const prevCalendarMatches = prevProps.resources['jobPostingCalendars/getCurrentCalendarMatches'];

    if (!isEqual(prevCalendarMatches, calendarMatches)) {
      this.setState({ calendarMatches });
    }
  }

  retrieveFastpassData = async () => {
    const { actions, user } = this.props;
    let activeFastpass = [];
    const fastpassRes = await actions.getAllResources(user.token, `fastpass/user/${user.id}`);
    activeFastpass = (fastpassRes?.response || []).filter(r => !r.deletedAt);
    this.setState({ fastpassData: activeFastpass });
  };

  refreshStats() {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'matches/stats');
    actions.getAllResources(user.token, 'messages/count-unread-msg-by-match')
      .then((res) => {
        if (!res.error) {
          const unreadMsgCounts = res.response || {};
          this.setState({ unreadMsgCounts });
        }
      });
  }

  refreshMatches(){
    const { actions, user } = this.props;
    setTimeout(() => actions.getAllResources(user.token, 'matches').then((res) => {
      this.getCalendarMatches();
      this.refreshStats();
    }), TIMEOUT_DURATION);
    
    actions.getAllResources(user.token, 'messages/count-unread-msg-by-match')
      .then((res) => {
        if (!res.error) {
          const unreadMsgCounts = res.response || {};
          this.setState({ unreadMsgCounts });
        }
      });
  }

  getCalendarMatches() {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'jobPostingCalendars/getCurrentCalendarMatches').then(() => {
      this.setCalendarFilterMatches();
    });
  }

  confirmConnectToMatch = (item) => {
    this.setState({
      openConfirmationModal: true,
      currentItem: item,
    });
  };

  makeConnection = (match_id) => {
    const {
      actions,
      user,
    } = this.props;

    return actions.updateResource('matches', user.token, {
      id: match_id,
      status: 'connected',
      connectDate: new Date(),
    }, 'matches', match_id)
      .then((e) => {
        actions.addResource('log-event', user.token, { action: 'talent_connect' }, 'users', 'log-event');
        if (e.type === types.UPDATE_RESOURCE_SUCCESS) {
          return this.setState({ openConfirmationModal: false });
        }
      });
  };

  connectToMatch = (item) => {
    const { history, actions, user } = this.props;
    this.makeConnection(item.id).then(() => {
      actions.getAllResources(user.token, 'messages').then(() => {
        history.push(`/messages/${this.state.currentItem.id}`);
      });
    });
  };

  renderConfirmEmail = (isMobile) => {
    const { user, classes, actions } = this.props;
    if (user && !user.isVerified) {
      return (
        <div className={classes.confirmEmailContainer} style={!isMobile ? { marginLeft: '26px', marginTop: '16px' }: {}}>
          <img className={classes.warningIcon} src={`${IMG_STORAGE_LINK}icons/warning.svg`} alt="warning" />
          <ConfirmEmail user={user} actions={actions} />
        </div>
      );
    }
    return null;
  };

  handleDeleteMatch = () => {
    const { actions, user, resources } = this.props;
    const { savedsearchs } = resources;
    const { matchToBeDeleted, filterSearchTitles } = this.state;
    const deletingMatchSearch = savedsearchs.find(search => search.id === matchToBeDeleted?.savedSearch?.id);

    if (deletingMatchSearch && !isEmpty(filterSearchTitles)) {
      this.setState({filterSearchTitles: filterSearchTitles.filter(t => t !== deletingMatchSearch.title)});
    }

    this.setState({
      openDeleteConfirmDialog: false,
      isMatchDeleted: true,
    }, () => {
      setTimeout(() => actions.deleteResource('matches', user.token, 'matches', matchToBeDeleted.id)
        .then(() => {
          this.getCalendarMatches();
          this.refreshStats();
        })
        .then(() => this.setState({ isMatchDeleted: false })), TIMEOUT_DURATION);
    });
  };

  handleTakeShift = async () => {
    const { actions, user, resources, history } = this.props;
    const { selectedEvent, shiftCalendarPractice,   fastpassData } = this.state;
    const { matches } = resources;
    if (isEmpty(selectedEvent) || !selectedEvent.jobId) return;
    const match = matches.find(m => m.id === parseInt(selectedEvent.matchId));
    if (match?.status !== 'connected') {
      await this.makeConnection(selectedEvent.matchId);
    }
    const isFastpass = !isEmpty(fastpassData.find(f => f.practice_id === shiftCalendarPractice?.id));
    const calendarResp = await actions.getAllResources(user.token, `jobpostings/${selectedEvent.jobId}/calendar`);
    if (calendarResp && calendarResp.response) {
      const avaiDataOnDate = calendarResp.response
      && calendarResp.response.find(row => row.status === 'available' && selectedEvent.date === row.date);

      if (!avaiDataOnDate) return;

      const newMessage = isFastpass ? `Hi there, I have FastPass for ${shiftCalendarPractice?.name} and I'm confirming this
        shift date as taken: ${moment(selectedEvent.date).format('MM/DD')}` : `Please confirm these dates: ${moment(selectedEvent.date).format('MM/DD')}`;
      let payload = {
        body: newMessage,
        match_id: parseInt(selectedEvent.matchId),
        isUnread: true,
      };
      if (isFastpass) {
        payload.takenDates = [{
          id: avaiDataOnDate.id,
          status: 'taken',
          date: avaiDataOnDate.date,
          job_posting_id: avaiDataOnDate.job_posting_id,
          talent_user_id: user.id,
          confirmation_category: 'known_taken',
          confirmation: `${user.name} (${user.email})`,
          confirmation_date: new Date(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }];
      } else {
        payload.requestedDates = [selectedEvent.date];
        payload.requestStatus = 'requested';
      }

      actions.addResource('messages', user.token, payload, 'messages').then((res) => {
        actions.addResource('log-event', user.token, { action: 'talent_message' }, 'users', 'log-event')
        .then(() => {
          this.setState({
            openDetailsDialog: false,
            openShiftCalendar: false,
            selectedEvent: {},
          }, () => {
            this.getCalendarMatches();
            this.refreshStats();
          });
        })
        .then(() => setTimeout(() => this.setState({ openShiftCalendar: true }), TIMEOUT_DURATION));
      });
    }
  }

  filterMatches = (matches, filterSearchTitles) => {
    if (isEmpty(filterSearchTitles) || isEmpty(matches)) return matches;
    let filteredMatches = [];
    filterSearchTitles.forEach((filterValue) => {
      filteredMatches = filteredMatches.concat(matches.filter(item => item.savedSearch.title === filterValue));
    });
    filteredMatches = uniqBy(filteredMatches, 'id');
    return filteredMatches;
  };

  updateCalendarMatches(filteredMatches) {
    const { resources } = this.props;
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    const originalAvailable = calendarMatches.available;
    const originalConfirmed = calendarMatches.confirmed;

    const available = this.filterDateByMatches(originalAvailable, filteredMatches);
    const confirmed = this.filterDateByMatches(originalConfirmed, filteredMatches);

    const calendarMatchesUpdated = { available, confirmed };

    this.setState({ calendarMatches: calendarMatchesUpdated });
  }

  filterDateByMatches(object, matches) {
    return object && Object.fromEntries(
      Object.entries(object).filter(([key, value]) => {
        return value.some(item => this.isInArray(item, matches))
      })
    )
  }

  isInArray = (value, array) => array && array.some(item => item?.id === value);

  filterMatchesByBadgeTitle = (matches, badgeTitle) => {
    if (isEmpty(badgeTitle) || isEmpty(matches)) return matches;
    let filteredMatches = [];
    filteredMatches = filteredMatches.concat(matches.filter(item => {
      if (badgeTitle === 'Coronado ER only') {
        return  item?.badges?.length === 1 && item?.badges?.find(b => b.title === 'Coronado ER');
      }
      return item?.badges?.find(b => b.title === badgeTitle);
    }));
    filteredMatches = uniqBy(filteredMatches, 'id');
    return filteredMatches;
  };

  filterMatchesByPractice = (matches, filterPracticeId) => {
    if (!filterPracticeId || isEmpty(matches)) return matches;
    let filteredMatches = [];
    filteredMatches = filteredMatches.concat(matches.filter(item => {
      return item?.practice?.id === +filterPracticeId;
    }));
    return filteredMatches;
  };

  filterMatchesByDates = (matches, filteredIds) => (matches || []).filter((match) => {
    return filteredIds.includes(match.id);
  });

  calcNewMessageCount = (unreadMsgCounts) => {
    return !isEmpty(unreadMsgCounts) ? Object.keys(unreadMsgCounts).length : 0;
  }

  getFilterButtons = (matches, unreadMsgCounts) => {
    const { resources } = this.props;
    const { savedsearchs } = resources;
    const matcheSearchIds = matches ? matches.map(match =>  match?.savedSearch?.id) : [];

    const matchesTitleMap = new Object();
    !isEmpty(savedsearchs) && savedsearchs.map(search => {
      if (matcheSearchIds.includes(search.id)) {
        Object.assign(matchesTitleMap, { [search.title]: search.color })
      } 
    });
    let searchCollection = [];
    for (const ssTitle in matchesTitleMap) {
      const ssColor = matchesTitleMap[ssTitle];
      const ssCount = matches.filter(m => m?.savedSearch?.title === ssTitle).length;
      searchCollection.push([ssTitle, ssColor, ssCount])
    }
    return [...searchCollection];
  }

  sortMatchesByCreatedAtInDescent = matches => [...matches].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  setCalendarFilterMatches = () => {
    const { calendarMatches } = this.state;

    if (isEmpty(calendarMatches)) return;

    const avaiFilterResult = this.setFilteredMatchItems(calendarMatches.available);
    const confirmedFilterResult = this.setFilteredMatchItems(calendarMatches.confirmed, true);
    const resultList = [...confirmedFilterResult.matchList, ...avaiFilterResult.matchList];
    const newFilteredDates = [...confirmedFilterResult.calendarFilterDates, ...avaiFilterResult.calendarFilterDates];

    this.setState({ items: resultList });

    // Reset localStorage filterDatesInStr
    this.setState({ filterDates: newFilteredDates });
    const fomattedDates = !isEmpty(newFilteredDates) ? newFilteredDates.map(d => moment(d).format('YYYY-MM-DD')) : [];
    localStorage.setItem('filterDatesInStr', fomattedDates.join(','));
  };

  setFilteredMatchItems = (calendarObj, isConfirmed) => {
    const { resources } = this.props;
    const { matches } = resources;
    const { filterDates, filter, filterSearchTitles, unreadMsgCounts } = this.state;
    const returnObj = { matchList: [], calendarFilterDates: [] };

    if (isEmpty(filterDates) || isEmpty(calendarObj)) return returnObj;

    const calendarKeys = Object.keys(calendarObj);
    if (isEmpty(calendarKeys)) return returnObj;

    const filteredMatches = matches ? this.filterMatches(matches, filterSearchTitles) : [];

    filterDates.forEach((filterDate) => {
      const foundDateKeys = calendarKeys.filter(dateKey => DateUtilities.isSameDay(filterDate, DateUtilities.midDayDate(dateKey)));
      let matchIds = [];
      foundDateKeys.forEach((key) => matchIds = matchIds.concat(calendarObj[key]));

      !isEmpty(matchIds) && matchIds.forEach((id) => {
        const matchObj = filteredMatches.find(m => m.id === id);
        if (matchObj) {
          returnObj.matchList.push({
            id,
            title: formatPostTitle(matchObj.jobPosting.title),
            practiceName: matchObj.practice.name,
            practiceId: matchObj.practice.id,
            practice: matchObj.practice,
            badges: matchObj.badges,
            jobPosting: matchObj.jobPosting,
            isConnected: matchObj.status === 'connected',
            isConfirmed
          });
        }
      });
    });
    returnObj.matchList = uniqBy(returnObj.matchList, 'id');
    const calendarDates = calendarKeys.map(d => DateUtilities.midDayDate(d));
    returnObj.calendarFilterDates = filterDates.filter(d => DateUtilities.dateIn(calendarDates, d));

    return returnObj;
  };

  setDateListForMatches = (matches, avaiObj, confirmedObj) => {
    if (isEmpty(matches) || (isEmpty(avaiObj) && isEmpty(confirmedObj))) return matches;
    const avaiKeys = Object.keys(avaiObj);
    const confirmedKeys = Object.keys(confirmedObj);

    let matchIdsObj = {};

    avaiKeys.forEach((dateKey) => {
      const ids = avaiObj[dateKey];
      ids.forEach((id) => {
        if (matchIdsObj[id]) {
          matchIdsObj[id] = matchIdsObj[id].concat([dateKey]);
        } else {
          matchIdsObj[id] = [dateKey];
        }
      });
    });
    let confirmedMatchIdsObj = {};
    !isEmpty(confirmedKeys) && confirmedKeys.forEach((dateKey) => {
      const ids = confirmedObj[dateKey];
      ids.forEach((id) => {
        if (confirmedMatchIdsObj[id]) {
          confirmedMatchIdsObj[id] = confirmedMatchIdsObj[id].concat([dateKey]);
        } else {
          confirmedMatchIdsObj[id] = [dateKey];
        }
      });
    });

    matches = matches.map((match) => {
      let dateList;
      let confirmedDateKeyList;
      let dates = [];

      if (isReliefOrExternshipType(match?.jobPosting?.typeOfEmployment)) {
        if (matchIdsObj[match.id]) {
          dates = matchIdsObj[match.id];
          dateList = dates.map(a => moment(a).format('MMM.DD')).join(', ');
        }
        if (confirmedMatchIdsObj[match.id]) {
          confirmedDateKeyList = confirmedMatchIdsObj[match.id];
        }
      }
      return { ...match, dateList, avaiDateKeyList: dates, confirmedDateKeyList }
    });
    return matches;
  };

  getEarliestDate = (dateList) => {
    const sortedDateList = dateList ? dateList.sort((date1, date2) => (date1 - date2)) : [];
    return sortedDateList.length ? sortedDateList[0] : null;
  }

  matchesOnSearch = matchesToDisplay => matchesToDisplay.filter(item => item?.jobPosting?.description && convertHTMLToText(item.jobPosting.description.toLowerCase()).includes(this.state.searchTerm.toLowerCase()));

  getItemPractices = (item) => {
    const { practices, state } = item;
    if (practices && practices.length) {
      let practiceNames = practices.map(practice => practice.name);
      if (practiceNames.length > 3) {
        return `${practiceNames.slice(0, 3).join(', ')}...`;
      } else {
        return practiceNames.join(', ');
      }
    }
    return state || '';
  }

  getSavedSearchItem = (item) => {
    const {
      classes, user, actions, resources,
    } = this.props;
    const { selectPracticeEnabled } = this.state;
    const locationText = selectPracticeEnabled && !item.isDefaultRelief
      ? this.getItemPractices(item)
      : item.address;

    return (
      <Grid container item xs={12} className={classes.ssItem} key={`ss_${item.id}`}>
        <Grid item xs={8} className={classes.ssItem}>
          <div
            key={`${item.id}_ss_icon`}
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: `#${item.color}`,
              marginRight: '4px',
            }}
          />
          <Typography className={classes.searchTitle}>
            {item.title === 'Externship Work Near Me' ? 'Externships Near Me' : item.title}
          </Typography>
        </Grid>
        <Grid item xs={4} container justifyContent='flex-end'>
          {(item.hasEmail || item.hasSms) ? <NotificationsIcon color='secondary' /> : <NotificationsOffIcon style={{ color: iconGray }} />}
          <Link
            className={classes.navLink}
            key={`edit_ss_${item.id}`}
            onClick={() => this.setState({ openSearchDialog: true, savedSearchItem: item, savedSearchId: item.id, enableDelete: true, nextColor: null })}
          >
            Edit
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.ssItem}>
          <PlaceIcon fontSize="small" className={classes.ssIcon} />
          <Typography className={classes.searchAddress}>{locationText}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    );
  };

  confirmationModalClicked = () => {
    const { actions, user, resources } = this.props;
    const { mobileVerificationCode } = this.state;
    const updatedUser = {
      mobileVerificationCode,
    };
    actions.updateResource('updateUserMobileCode', user.token, updatedUser, 'users', user.id)
      .then(this.setState({ openPhoneVerification: false }));
  }

  setMarkers = (matchesToDisplay) => {
    const totalMatches = !isEmpty(matchesToDisplay) ? matchesToDisplay.map(i => ({
      ...i,
      practiceId: i?.practice?.id,
    })) : [];
    const allLocations = !isEmpty(totalMatches) ? uniqBy(totalMatches, 'practiceId').map(item => item.practice) : [];
    const markers = !isEmpty(allLocations) ? allLocations.filter(l => l.lat && l.lng)
      .map(l => {
        const matchCount = totalMatches.filter(m => m.practiceId === l.id).length;
        return { id: l.id, name: l.name, position: { lat: l.lat, lng: l.lng }, matchCount };
      }) : [];
    return { markers, totalMatches };
  };

  getRotationTypes = (matches) => {
    let rotationTypes = [];

    matches && matches.forEach(match => {
      if (match.jobPosting && match.jobPosting.typeOfEmployment === 'Externship' && match.jobPosting.rotationTypes !== null) {
        rotationTypes = [...rotationTypes, JSON.parse(match.jobPosting.rotationTypes)]
      }
    })

    return uniq(flatMapDeep(rotationTypes));
  }

  renderMobile = () => {
    const {
      classes, resources, actions, user, search, history,
    } = this.props;
    const {
      openDeleteConfirmDialog,
      items,
      filterDates,
      fastpassData,
      practiceFilterMatchIds,
      unreadMsgCounts,
      calendarDrawerOpen,
      searchDrawerOpen,
      mapView,
      filterSearchTitles = [],
      openMobileCalendar,
      isMatchDeleted,
      matchToBeDeleted,
    } = this.state;
    const {
      allMatches,
      matchBadgeTitles,
      matchesToDisplayOnSearch,
      availableDates,
      confirmedDates,
      markers,
      totalMatches,
      filterBtns,
      shiftCalendarMatches,
      matchPractices,
    } = this.prepareData();

    const element = document.getElementById('mobileJobCardContainer');
    const calendarData = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    return (
      <>
        <div style={{ position: 'fixed', zIndex: 200 }}>
          <MatchFilter
            isMobile
            history={history}
            practiceFilterMatchIds={practiceFilterMatchIds}
            filterSearchTitles={filterSearchTitles}
            filterBtns={filterBtns}
            filterDates={filterDates}
            resetFilter={() => {
              const { resources } = this.props;
              const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
              this.setState({ searchTerm: '', filterSearchTitles: [], filterDates: [], items: [], rotationType: '', calendarMatches });
              localStorage.setItem('filterDatesInStr', '');
            }}
            searchTerm={this.state.searchTerm}
            setSearchTerm={(value) => this.setState({ searchTerm: value })}
            searchHandle={(selectedItems = []) => {
              let titles = selectedItems.map(item => item.title);
              this.setState({ filter: !isEmpty(titles) ? titles[0] : '', filterSearchTitles: titles }, () => {
                const filteredMatches = this.filterMatches(allMatches, titles);
                this.updateCalendarDates(filteredMatches);
              });
            }}
            badgeTitles={matchBadgeTitles}
            matchBadgeTitle={this.state.matchBadgeTitle}
            onChangeBadgeTitles={(title) => {
              this.setState({ matchBadgeTitle: title }, () => {
                localStorage.setItem('matchBadgeTitle', title);
                const filteredMatches = this.filterMatchesByBadgeTitle(allMatches, title);
                this.updateCalendarDates(filteredMatches);
              });
            }}
            disableCalendarIcon={openMobileCalendar}
            handleSetState={() => this.setState({ openMobileCalendar: true })}
            matchPractices={matchPractices}
            filterPracticeId={this.state.filterPracticeId}
            onChangePractices={(practiceId) => {
              this.setState({ filterPracticeId: practiceId }, () => {
                localStorage.setItem('filterPracticeId', practiceId);
                const filteredMatches = this.filterMatchesByPractice(allMatches, practiceId);
                this.updateCalendarDates(filteredMatches);
              });
            }}
          />
        </div>
        <Grid container className={classes.mobileRoot}>
          {user && !user.isVerified && (<Grid item >{this.renderConfirmEmail(true)}</Grid>)}
          {openMobileCalendar && (<>
            <Grid item style={{ borderBottom: `1px solid ${lightGray}` }}>
              <CalendarFilter
                availableDates={availableDates || []}
                confirmedDates={confirmedDates || []}
                initialDate={filterDates.length ? this.getEarliestDate(filterDates) : this.getEarliestDate(availableDates)}
                handleStateChange={(filterDates) => {
                  this.setState({
                    filterDates,
                  }, () => {
                    this.setCalendarFilterMatches();
                  });
                }}
                outerFilterDates={[...filterDates]}
                isMobile
                surgeryNeededList={calendarData?.surgeryNeeded || []}
                boosted={calendarData?.boosted || []}
              />

            </Grid>
            <Grid item className={classes.closeIconContainer}>
              <Box className={classes.topDrawerCloseBtn}>
                <IconButton onClick={() => this.setState({ openMobileCalendar: false })} size='small'>
                  <KeyboardArrowUpIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
            </Grid></>
          )}

          <Grid item className={classes.matchMobileContainer}>
            {/* Map view */}
            {mapView && (<>
              <div style={{ padding: '0px 16px 16px 16px', display: 'flex', justifyContent: 'center' }}>
                <MatchesSearchBar
                  value={this.state.searchTerm}
                  onChange={value => this.setState({ searchTerm: value })}
                  placeholder='Search description'
                  onClickClearIcon={() => this.setState({ searchTerm: '' })}
                  className={classes.matchSeachBar}
                />
              </div>
              <div className={classes.mMapContainer}>
                {isEmpty(matchesToDisplayOnSearch) && this.renderNoNatchesAlert(true)}
                <LocationGoogleMap
                  isMobile
                  markers={markers}
                  totalItems={totalMatches}
                  fastpassData={fastpassData}
                  containerStyle={{
                    padding: 0,
                    margin: 0,
                    with: '100%',
                    height: '100%',
                    overflowY: 'visible',
                  }}
                />
              </div>
            </>)}
            {/* Card view */}
            {!mapView && (
              <Grid item className={classes.mJobCardContainer} id='mobileJobCardContainer'
                onScroll={(e) => {
                  if (element?.scrollTop >= 200) {
                    this.setState({ openMobileCalendar: false });
                  }
                }}
              >
                <Grid container className={classes.jobMatchContainer}>
                  <Grid item xs={12} style={{ padding: '0px 16px 16px 16px', display: 'flex', justifyContent: 'center' }}>
                    <MatchesSearchBar
                      value={this.state.searchTerm}
                      onChange={value => this.setState({ searchTerm: value })}
                      placeholder='Search description'
                      onClickClearIcon={() => this.setState({ searchTerm: '' })}
                      className={classes.matchSeachBar}
                    />
                  </Grid>
                  {this.state.isLoading
                    ? (<div className={classes.loading}><CircularProgress color="inherit" /></div>)
                    : (
                      isEmpty(matchesToDisplayOnSearch)
                      ? this.renderNoNatchesAlert(true)
                      : (matchesToDisplayOnSearch.map(item => (
                        <Grid key={item.id} item
                          xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                          style={{ padding: '0px 16px 16px 16px' }}
                        >
                          <JobMatchCard
                            enableDelete
                            user={user}
                            refreshStats={() => this.refreshStats()}
                            actions={actions}
                            item={item}
                            reliefDates={this.state.items}
                            confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                            unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
                            deleteMatch={() => this.setState({
                              openDeleteConfirmDialog: true,
                              matchToBeDeleted: item,
                            })}
                            showSchedule={(item) => {
                              this.setState({
                              openShiftCalendar: true,
                              shiftCalendarPractice: item?.practice || {},
                              });
                            }}
                            handlePracticeClick={(practiceId) => {
                              this.setState({ filterPracticeId: practiceId });
                            }}
                            open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                            fastpassData={fastpassData}
                            isMobile
                          />
                        </Grid>
                      ))))
                  }
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Fab
          variant="extended"
          className={classes.mapViewFabBtn}
          onClick={() => {
            this.setState({ mapView: !mapView }, () => {
              if (this.state.mapView) {
                this.setState({ openMobileCalendar: false })
              }
            });
          }}
        >
          {mapView ? (<StorageOutlinedIcon className={classes.matchIteminfoIcon} color={orange} />) : <PlaceIcon className={classes.matchIteminfoIcon} />}
          <Typography>{mapView ? 'Card View' : 'Map View'}</Typography>
        </Fab>

        {renderConnectModal({
          ...this.props,
          openConfirmationModal: this.state.openConfirmationModal,
          currentItem: this.state.currentItem,
          onClose: () => this.setState({ openConfirmationModal: false }),
          onOk: () => this.connectToMatch(this.state.currentItem),
        })}

        <DeleteConfirmDialog
          resourceName="Job Post"
          open={openDeleteConfirmDialog}
          handleDelete={() => this.handleDeleteMatch()}
          handleClose={() => this.setState({ openDeleteConfirmDialog: false })}
          jobTitle={matchToBeDeleted && matchToBeDeleted.jobPosting && formatPostTitle(matchToBeDeleted.jobPosting.title)}
          practiceName={matchToBeDeleted && matchToBeDeleted.practice && matchToBeDeleted.practice.name}
          message="Are you sure you want to remove the post below - any new dates added to this post will not be visible!"
          hasCancelButton
        />
        {this.state.openShiftCalendar &&
        <ShiftCalendarDialog
          open={this.state.openShiftCalendar}
          handleClose={() => this.setState({ openShiftCalendar: false })}
          handleEventSelect={(event) => this.setState({ selectedEvent: event, openDetailsDialog: true })}
          shiftCalendarPractice={this.state.shiftCalendarPractice}
          shiftCalendarMatches={shiftCalendarMatches}
          isMobile
        />
        }
        {this.state.openDetailsDialog && (
          <JobDetailsDialog
            open={this.state.openDetailsDialog}
            handleClose={() => this.setState({ openDetailsDialog: false })}
            handleTakeShift={() => this.handleTakeShift()}
            matchData={shiftCalendarMatches?.find(match => match.id === this.state.selectedEvent?.matchId) || {}}
            selectedEvent={this.state.selectedEvent}
            isFastpass={!isEmpty(fastpassData.find(f => f.practice_id === this.state.shiftCalendarPractice?.id))}
          />
        )}
      </>
    );
  }

  renderBreadcrumb = (filterDates, filterSearchTitles) => {
    const { classes } = this.props;

    return (
      <div className={classes.bcRoot}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ minWidth: '160px' }}>
          <Typography onClick={() => { }} className={classes.text}>
            My Matches
          </Typography>
          <Typography className={classes.linkText} style={{ color: tiffany }}>
            All
          </Typography>
          <Typography className={classes.linkText}></Typography>
        </Breadcrumbs>

        {!isEmpty(filterDates) && filterDates.map((date, index) => (
          <Chip
            variant="outlined"
            label={moment(date).format('MMM. DD')}
            size="small"
            key={`date_${index}`}
            className={classes.chipText}
            onDelete={() => {
              filterDates.splice(index, 1);
              this.setState({ filterDates });
              this.setState({
                filterDates,
              }, () => {
                this.setCalendarFilterMatches();
              });
            }}
          />
        ))}
        {!isEmpty(filterSearchTitles) && filterSearchTitles.map((title, index) => (
          <Chip
            variant="outlined"
            label={title}
            size="small"
            key={`search_${index}`}
            className={classes.chipText}
            onDelete={() => {
              filterSearchTitles.splice(index, 1);

              if (isEmpty(filterSearchTitles)) {
                const { resources } = this.props;
                const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
                this.setState({ calendarMatches });
              }
              
              this.setState({ filterSearchTitles });
            }}
          />
        ))}
      </div>
    )
  }

  filterItemsByDates = (allItems, filteredIds) => (allItems || []).filter((item) => {
    return filteredIds.includes(item.id);
  });

  prepareData = () => {
    const { resources } = this.props;
    const { matches } = resources;
    const {
      items,
      filterDates,
      fastpassData,
      practiceFilterMatchIds,
      unreadMsgCounts,
      calendarDrawerOpen,
      searchDrawerOpen,
      mapView,
      locations = [],
      filterSearchTitles = [],
      matchBadgeTitle,
      calendarMatches, isMatchDeleted, matchToBeDeleted,
      shiftCalendarPractice,
      filterPracticeId,
    } = this.state;
    const allMatches = matches || [];
    const filterBtns = this.getFilterButtons(allMatches, unreadMsgCounts);

    let matchBadges = [];
    !isEmpty(allMatches) && [...allMatches].forEach((m) => {
      if (!isEmpty(m.badges)) {
        matchBadges = matchBadges.concat(...m.badges);
      }
    });
    matchBadges = uniqBy(matchBadges, 'title');
    let matchBadgeTitles = matchBadges.map(m => m.title);
    if (matchBadgeTitles.length > 1 && matchBadgeTitles.includes('Coronado ER')) {
      matchBadgeTitles = [...matchBadgeTitles, 'Coronado ER only'];
    }
    matchBadgeTitles = matchBadgeTitles.sort();

    let matchPractices = [];
    !isEmpty(allMatches) && [...allMatches].forEach((m) => {
      if (!isEmpty(m.practice)) {
        matchPractices.push({ id: m.practice.id, name: m.practice.name || ''});
      }
    });
    matchPractices = uniqBy(matchPractices, 'id');
    matchPractices = sortBy(matchPractices, 'name');

    let filteredItems = this.filterMatches(allMatches, filterSearchTitles);
    // filteredItems = !isEmpty(items) ? this.filterMatchesByDates(filteredItems, items.map(item => item.id)) : filteredItems;
    const matchesToDisplay = this.sortMatchesByCreatedAtInDescent(filteredItems);
    let matchesToDisplayOnSearch = this.matchesOnSearch(matchesToDisplay);

    if (matchBadgeTitle !== '') {
      matchesToDisplayOnSearch = this.filterMatchesByBadgeTitle(matchesToDisplayOnSearch, matchBadgeTitle);
    }
    if (filterPracticeId) {
      matchesToDisplayOnSearch = this.filterMatchesByPractice(matchesToDisplayOnSearch, filterPracticeId);
    }

    const { calendarMatchesUpdated } = this.updateCalendarDates(matchesToDisplayOnSearch);
    const availableDates = calendarMatchesUpdated
    && calendarMatchesUpdated.available ? Object.keys(calendarMatchesUpdated.available).map(d => DateUtilities.midDayDate(d)) : [];
    const confirmedDates = calendarMatchesUpdated
      && calendarMatchesUpdated.confirmed ? Object.keys(calendarMatchesUpdated.confirmed).map(d => DateUtilities.midDayDate(d)) : [];

    matchesToDisplayOnSearch = !isEmpty(items)
      ? this.filterItemsByDates(matchesToDisplayOnSearch, items.map(item => item.id)) : matchesToDisplayOnSearch;

    if (!isEmpty(calendarMatchesUpdated?.available) || !isEmpty(calendarMatchesUpdated?.confirmed)) {
      matchesToDisplayOnSearch = this.setDateListForMatches(matchesToDisplayOnSearch, calendarMatches?.available, calendarMatches?.confirmed);
    }

    const { markers, totalMatches } = this.setMarkers(matchesToDisplayOnSearch);

    let shiftCalendarMatches = [];
    if (!isEmpty(shiftCalendarPractice)) {
      shiftCalendarMatches = [...matchesToDisplayOnSearch].filter(match => match?.practice?.id === shiftCalendarPractice?.id);
    }

    return {
      allMatches,
      matchBadgeTitles,
      matchesToDisplayOnSearch,
      availableDates,
      confirmedDates,
      markers,
      totalMatches,
      filterBtns,
      shiftCalendarMatches,
      matchPractices,
    };
  }

  updateCalendarDates(filteredMatches) {
    const { resources } = this.props;

    // matches
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
    let calendarMatchesUpdated = {};

    if (calendarMatches) {
    const originalAvailable = calendarMatches?.available;
    const originalConfirmed = calendarMatches?.confirmed;

    const available = this.filterDateByMatches(originalAvailable, filteredMatches);
    const confirmed = this.filterDateByMatches(originalConfirmed, filteredMatches);
    calendarMatchesUpdated = { available, confirmed };
    }

    return { calendarMatchesUpdated };
  }

  renderNoNatchesAlert = (isMobile) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open
        key='snackbar_nomatches'
        style={{ top: isMobile ? '70vh' : '200px', zIndex: '2' }}
      >
        <Alert variant="outlined" severity="warning"
        iconMapping={{
          warning: <InfoIcon fontSize="inherit" />,
        }}
        style={{ backgroundColor: '#fff' }}
        ><strong>No matches!</strong> Try broading your search and using fewer filters.</Alert>
      </Snackbar>
    );
  }

  renderDesktop = () => {
    const {
      classes, resources, actions, user, search, history,
    } = this.props;
    const {
      openDeleteConfirmDialog,
      items,
      filterDates,
      fastpassData,
      practiceFilterMatchIds,
      unreadMsgCounts,
      calendarDrawerOpen,
      searchDrawerOpen,
      mapView,
      filterSearchTitles = [],
      isMatchDeleted, matchToBeDeleted,
    } = this.state;
    const { savedsearchs = [] } = resources;

    const {
      allMatches,
      matchBadgeTitles,
      matchesToDisplayOnSearch,
      availableDates,
      confirmedDates,
      markers,
      totalMatches,
      filterBtns,
      shiftCalendarMatches,
      matchPractices,
    } = this.prepareData();

    const allSavedsearches = savedsearchs || [];
    const calendarData = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    return (
      <div className={classes.root}>
        <div style={{ position: 'fixed', zIndex: 200 }}>
          <MatchFilter
            history={history}
            practiceFilterMatchIds={practiceFilterMatchIds}
            filterSearchTitles={filterSearchTitles}
            filterBtns={filterBtns}
            resetFilter={() => {
              const { resources } = this.props;
              const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
              this.setState({ searchTerm: '', filterSearchTitles: [], filterDates: [], items: [], rotationType: '', calendarMatches });
              localStorage.setItem('filterDatesInStr', '');
            }}
            searchTerm={this.state.searchTerm}
            setSearchTerm={(value) => this.setState({ searchTerm: value })}
            searchHandle={(selectedItems = []) => {
              let titles = selectedItems.map(item => item.title);

              this.setState({ filter: !isEmpty(titles) ? titles[0] : '', filterSearchTitles: titles }, () => {
                const filteredMatches = this.filterMatches(allMatches, titles);
                this.updateCalendarDates(filteredMatches);
              });
            }}
            badgeTitles={matchBadgeTitles}
            matchBadgeTitle={this.state.matchBadgeTitle}
            onChangeBadgeTitles={(title) => {
              this.setState({ matchBadgeTitle: title }, () => {
                localStorage.setItem('matchBadgeTitle', title);
                const filteredMatches = this.filterMatchesByBadgeTitle(allMatches, title);
                this.updateCalendarDates(filteredMatches);
              });
            }}
            matchPractices={matchPractices}
            filterPracticeId={this.state.filterPracticeId}
            onChangePractices={(practiceId) => {
              this.setState({ filterPracticeId: practiceId }, () => {
                localStorage.setItem('filterPracticeId', practiceId);
                const filteredMatches = this.filterMatchesByPractice(allMatches, practiceId);
                this.updateCalendarDates(filteredMatches);
              });
            }}
          />
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={calendarDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Typography variant='subtitle1' className={classes.info}>Select available dates to filter matches!</Typography>
            <Box className={classes.leftDrawerCloseBtn}>
              <IconButton onClick={() => this.setState({ calendarDrawerOpen: false })} size='small'>
                <ChevronLeftIcon style={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          </div>
          <div>
            <CalendarFilter
              availableDates={availableDates || []}
              confirmedDates={confirmedDates || []}
              initialDate={filterDates.length ? this.getEarliestDate(filterDates) : this.getEarliestDate(availableDates)}
              handleStateChange={(filterDates) => {
                this.setState({
                  filterDates,
                }, () => {
                  this.setCalendarFilterMatches();
                });
              }}
              outerFilterDates={[...filterDates]}
              surgeryNeededList={calendarData?.surgeryNeeded || []}
              boosted={calendarData?.boosted || []}
            />
          </div>
        </Drawer>
        {!calendarDrawerOpen && (
          <Fab
            variant="extended"
            className={classes.drawerFabBtn} style={{ left: -31, top: 285, rotate: 'z 90deg', }}
            onClick={() => this.setState({ calendarDrawerOpen: true })}
          >
            Calendar
          </Fab>
        )}

        <div
          className={classNames(classes.matchContainer, {
            [classes.contentShiftLeft]: calendarDrawerOpen,
            [classes.contentShiftRight]: searchDrawerOpen,
          })}
        >
          <Toolbar
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              padding: 0,
              marginTop: '37px',
              width: '100%',
              minHeight: mapView ? '0px' : '67px',
            }}
          >
            {this.renderConfirmEmail()}
            {/* {(!isEmpty(filterDates) || !isEmpty(filterSearchTitles)) && this.renderBreadcrumb(filterDates, filterSearchTitles)} */}
            {!mapView && !isEmpty(matchesToDisplayOnSearch) &&
            <div className={classes.bcRoot}>
              <div className={classes.matchTitleContainer}>
                <Typography color="secondary" className={classes.title}>My Matches</Typography>
              </div>
            </div>
            }
          </Toolbar>
          {/* Map view */}
          {mapView && (
            <div className={classes.mapContainer}>
              {isEmpty(matchesToDisplayOnSearch) && this.renderNoNatchesAlert()}
              <LocationGoogleMap
                markers={markers}
                totalItems={totalMatches}
                fastpassData={fastpassData}
                containerStyle={{
                  padding: 0,
                  margin: 0,
                  with: '100%',
                  height: '100%',
                  overflowY: 'visible',
                }}
              />
            </div>
          )}
          {/* Card view */}
          {!mapView && (
            <div className={classes.jobCardContainer}>
              <Grid container className={classes.jobMatchContainer}>
                {this.state.isLoading
                  ? (<div className={classes.loading}><CircularProgress color="inherit" /></div>)
                  : isEmpty(matchesToDisplayOnSearch)
                    ? this.renderNoNatchesAlert()
                    : (matchesToDisplayOnSearch.map(item => (
                      <Grid key={item.id} item
                        xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                        style={{ padding: '0px 16px 16px 16px' }}
                      >
                        <JobMatchCard
                          enableDelete
                          user={user}
                          refreshStats={() => this.refreshStats()}
                          actions={actions}
                          item={item}
                          reliefDates={this.state.items}
                          confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                          unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
                          deleteMatch={() => this.setState({
                            openDeleteConfirmDialog: true,
                            matchToBeDeleted: item,
                          })}
                          showSchedule={(item) => {
                            this.setState({
                              openShiftCalendar: true,
                              shiftCalendarPractice: item?.practice || {},
                            });
                          }}
                          handlePracticeClick={(practiceId) => {
                            this.setState({ filterPracticeId: practiceId });
                          }}
                          open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                          fastpassData={fastpassData}
                        />
                      </Grid>
                    )))
                }
              </Grid>
            </div>
          )}
        </div>

        <Fab
          variant="extended"
          className={classes.mapViewFabBtn}
          onClick={() => this.setState({ mapView: !mapView })}
        >
          {mapView ? (<StorageOutlinedIcon className={classes.matchIteminfoIcon} color={orange} />) : <PlaceIcon className={classes.matchIteminfoIcon} />}
          <Typography>{mapView ? 'Card View' : 'Map View'}</Typography>

        </Fab>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={searchDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box className={classes.rightDrawerCloseBtn} >
            <IconButton onClick={() => this.setState({ searchDrawerOpen: false })} size='small'>
              <ChevronRightIcon style={{ fontSize: '16px' }} />
            </IconButton>
          </Box>
          <div className={classes.drawerHeader} style={{ justifyContent: 'space-between', padding: '0 20px', backgroundColor: bgGray, height: '50px' }}>
            <Typography color="secondary" className={classes.drawerHeading}>My Saved Searches</Typography>
            <Tooltip title='Create new search' placement='left'>
              <IconButton
                onClick={() => {
                  this.setState({ openSearchDialog: true, enableDelete: false, savedSearchItem: null });
                }}
                size='small'>
                <ControlPointIcon size='small' color='primary' />
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
          <div className={classes.drawerContainer}>
            {allSavedsearches.map((item) => this.getSavedSearchItem(item))}
          </div>

        </Drawer>
        {!searchDrawerOpen && (
          <Fab
            variant="extended"
            className={classes.drawerFabBtn} style={{ right: -41, top: 315, rotate: 'z -90deg' }}
            onClick={() => this.setState({ searchDrawerOpen: true })}
          >
            My Saved Searches
          </Fab>
        )}

        {renderConnectModal({
          ...this.props,
          openConfirmationModal: this.state.openConfirmationModal,
          currentItem: this.state.currentItem,
          onClose: () => this.setState({ openConfirmationModal: false }),
          onOk: () => this.connectToMatch(this.state.currentItem),
        })}

        <DeleteConfirmDialog
          resourceName="Job Post"
          open={openDeleteConfirmDialog}
          handleDelete={() => this.handleDeleteMatch()}
          handleClose={() => this.setState({ openDeleteConfirmDialog: false })}
          jobTitle={matchToBeDeleted && matchToBeDeleted.jobPosting && formatPostTitle(matchToBeDeleted.jobPosting.title)}
          practiceName={matchToBeDeleted && matchToBeDeleted.practice && matchToBeDeleted.practice.name}
          message="Are you sure you want to remove the post below - any new dates added to this post will not be visible!"
          hasCancelButton
        />
        <SavedSearchDialog
          open={Boolean(this.state.openSearchDialog)}
          user={user}
          actions={actions}
          resources={resources}
          savedSearch={this.state.savedSearchItem}
          openPhoneVerification={() => this.setState({ openPhoneVerification: true })}
          handleClose={isSaved => {
            if (isSaved) {
              actions.getAllResources(user.token, 'savedsearchs').then((resp) => {
                const searchTitles = resp?.response?.map(s => s.title);
                const updatedSearchTitles = searchTitles ? filterSearchTitles.filter(t => searchTitles.includes(t)) : [];
                this.setState({ filterSearchTitles: updatedSearchTitles });
                this.refreshMatches();
                this.getCalendarMatches();
                this.refreshStats();
              });
            }
            this.setState({ openSearchDialog: false });
          }}
          enableDelete={this.state.enableDelete}
        />
        {renderPhoneVerificationModal({
          ...this.props,
          openPhoneVerification: this.state.openPhoneVerification,
          onClose: () => this.setState({ openPhoneVerification: false }),
          onOk: () => this.confirmationModalClicked(),
          handleStateChange: (e) => this.setState({ mobileVerificationCode: e.target.value }),
        })
        }
        {this.state.openShiftCalendar &&
        <ShiftCalendarDialog
          open={this.state.openShiftCalendar}
          handleClose={() => this.setState({ openShiftCalendar: false })}
          handleEventSelect={(event) => this.setState({ selectedEvent: event, openDetailsDialog: true })}
          shiftCalendarPractice={this.state.shiftCalendarPractice}
          shiftCalendarMatches={shiftCalendarMatches}
        />
        }
        {this.state.openDetailsDialog && (
          <JobDetailsDialog
            open={this.state.openDetailsDialog}
            handleClose={() => this.setState({ openDetailsDialog: false })}
            handleTakeShift={() => this.handleTakeShift()}
            matchData={shiftCalendarMatches?.find(match => match.id === this.state.selectedEvent?.matchId) || {}}
            selectedEvent={this.state.selectedEvent}
            isFastpass={!isEmpty(fastpassData.find(f => f.practice_id === this.state.shiftCalendarPractice?.id))}
          />
        )}
      </div>
    );
    
  }

  render() {
    const { isDesktop } = this.props;

    if (!isDesktop && Boolean(this.state.openSearchDialog)) {
      return <Redirect to='/search' />
    }

    return !isDesktop ? this.renderMobile() : this.renderDesktop();
  }
}

MatchesComponent.propTypes = {};

function MatchesComponentWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <MatchesComponent {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(MatchesComponentStyle, { withTheme: true })(withRouter(MatchesComponentWrapper));
