import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import { defaultUtils as utils } from './dateUtils';
import moment from 'moment';
import  DateUtilities, { capitalizeFirstLetter } from './utils';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import WeekHeader from './WeekHeader';
import Month from './Month';
import LensIcon from '@mui/icons-material/Lens';
import EventIcon from '@mui/icons-material/Event';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { green, navy, orange, orangeLight, gray } from '../../css/style';
import { isEmpty } from 'lodash';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  toolbarButton: {
    color: '#233160',
  },
  dateText: {
    fontFamily: 'Asap',
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    color: '#233160',
  },
  note: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noteText: {
    fontFamily: 'Asap',
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
    color: '#404A59',
  },
  squareTag: {
    width: '10px',
    height: '10px',
    borderRadius: '2px',
  },
  boostText: {
    fontFamily: 'Asap',
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontWeight: 400,
    },
  },
  buttonContainer: {
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  resetButton: {
    background: '#ffffff',
    border: '1px solid #233160',
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
    color: '#233160',
    borderRadius: 4,
    textTransform: 'capitalize',
    '&:disabled': {
      border: '1px solid #CACACE',
    }
  },
  submitButton: {
    background: '#364D96',
    border: '1px solid #364D96',
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: 4,
    textTransform: 'capitalize',
    marginLeft: 10,
    minWidth: 70,
    '&:hover': {
      background: '#233160',
    },
    '&:disabled': {
      background: '#DADFF1',
      border: '1px solid #DADFF1',
      color: '#ffffff',
    }
  },
  showShiftCalendarBtn: {
    background: orange,
    border: `1px solid ${orange}`,
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: 4,
    textTransform: 'capitalize',
    margin: '10px 0px',
    minWidth: 70,
    '&:hover': {
      background: orange,
    },
    '&:disabled': {
      background: '#DADFF1',
      border: '1px solid #DADFF1',
      color: '#ffffff',
    },
  },
  mShowShiftCalendarBtn: {
    color: '#ffffff !important',
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    textTransform: 'capitalize',
    '&:disabled': {
      color: '#64748B !important',
    }
  },
  mButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#233160',
    padding: 10,
    marginTop: 20,
  },
  mResetButton: {
    color: '#ffffff !important',
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    textTransform: 'capitalize',
    '&:disabled': {
      color: '#64748B !important',
    }
  },
  mSubmitButton: {
    color: '#ffffff !important',
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    textTransform: 'capitalize',
    '&:disabled': {
      color: '#64748B !important',
    }
  },
  mCalendarContainer: {
    width: '100%',
    minHeight: '60px',
    overflowY: 'auto',
    padding: '0px 16px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      maxWidth: '350px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
  },
  takeShiftInfoIcon: {
    color: 'white',
    marginRight: 5,
  },
  error: {
    color: 'red',
    paddingTop: 8,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

const SingleCalendar = ({
  warningMsg,
  selectedDates,
  notAvailableDates,
  requestedDates,
  confirmedDates,
  onSelect,
  onCancel,
  onOk,
  readOnly,
  cancelButtonText,
  submitButtonText,
  pendingDates,
  isFastpass = false,
  largeSize = false,
  isMobile,
  isMobileTop,
  calendarData,
  showShiftCalendar,
  initialDate,
  shiftStartTime,
  onShiftCalendarClick = () => {},
}) => {
  const classes = useStyles();
  const calendar = useRef(null);
  const [displayDate, setDisplayDate] = useState(() => utils.getFirstDayOfMonth(new Date()))
  const dateTimeFormatted = moment(displayDate).format('MMMM YYYY');
  const maxDate = utils.addYears(new Date(), 100);
  const minDate = utils.addYears(new Date(), -100);
  const toolbarInteractions = {
    prevMonth: utils.monthDiff(displayDate, minDate) > 0,
    nextMonth: utils.monthDiff(displayDate, maxDate) < 0,
  };

  const [boostedRows, setBoostedRows] = useState([]);
  const [boostDetails, setBoostDetails] = useState({});

  useEffect(() => {
    if (initialDate) {
      setDisplayDate(new Date(initialDate));
    }
  }, [initialDate])

  useEffect(() => {
    if (!isEmpty(calendarData)) {
      const boostedRows = calendarData.filter(row =>  row.status === 'available' && row.is_boosted);
      setBoostedRows(boostedRows);
    }
  }, [calendarData]);

  useEffect(() => {
    if (!readOnly && requestedDates?.length > 0 && boostedRows?.length > 0) {
      const boostedRow = boostedRows.find(r => DateUtilities.isSameDay(DateUtilities.midDayDate(r.date), requestedDates[requestedDates.length - 1]));
      if(boostedRow) {
        setBoostDetails({ amount: boostedRow?.boost_amount, date: boostedRow?.date });
      }
    } else {
      setBoostDetails({});
    }
  }, [requestedDates, readOnly]);

  const handleTouchTapPrevMonth = (e) => {
    e.preventDefault();
    handleMonthChange(-1);
  };

  const handleTouchTapNextMonth = (e) => {
    e.preventDefault();
    handleMonthChange(1);
  };

  const handleMonthChange = useCallback(
    (months) => {
      setDisplayDate(displayDate => utils.addMonths(displayDate, months));
    },
    [setDisplayDate],
  );

  const renderCalendar = (displayDate, dateTimeFormatted) => (
    <div className={classes.mCalendarContainer}>
      <Grid item xs={12} className={classes.dateTextContainer} key={`monthName_${dateTimeFormatted}`}>
        <div className={classes.toolbar}>
          <IconButton className={classes.toolbarButton} disabled={!toolbarInteractions.prevMonth} onClick={handleTouchTapPrevMonth}>
            <ArrowBackIos fontSize='small' />
          </IconButton>
          <Typography variant='subtitle1' className={classes.dateText}>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
          <IconButton className={classes.toolbarButton} disabled={!toolbarInteractions.nextMonth} onClick={handleTouchTapNextMonth}>
            <ArrowForwardIos fontSize='small' />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12} key={`weekHeader_${dateTimeFormatted}`}>
        <WeekHeader largeSize />
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '16px' }} key={`month_${dateTimeFormatted}`}>
        <Month
          displayDate={displayDate}
          key={displayDate.toDateString()}
          shiftStartTime={shiftStartTime}
          selectedDates={selectedDates}
          requestedDates={requestedDates}
          notAvailableDates={notAvailableDates}
          pendingDates={pendingDates}
          confirmedDates={confirmedDates || []}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={onSelect}
          readOnly={readOnly}
          ref={calendar}
          isFastpass={isFastpass}
          largeSize={largeSize}
          calendarData={calendarData}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ justifyContent: 'space-around' }}>
          <Grid item className={classes.note}>
            <LensIcon style={{
              width: 12,
              height: 12,
              color: green
            }}/>
            <Typography className={classes.noteText}>Available</Typography>
          </Grid>
          <Grid item className={classes.note}>
            <RadioButtonUncheckedIcon style={{
              width: 12,
              height: 12,
              color: orange
            }}/>
            <Typography className={classes.noteText}>Taken</Typography>
          </Grid>
          <Grid item className={classes.note}>
            <LensIcon style={{
              width: 12,
              height: 12,
              color: isFastpass ? orangeLight : navy
            }}/>
            <Typography className={classes.noteText}>{isFastpass ? 'Take Shift' : 'Requested'}</Typography>
          </Grid>
          {!isEmpty(pendingDates) && (
            <Grid item className={classes.note}>
              <LensIcon style={{
                width: 12,
                height: 12,
                color: gray
              }}/>
              <Typography className={classes.noteText}>Pending</Typography>
            </Grid>
          )}
          {!isEmpty(confirmedDates) && (
            <Grid item className={classes.note}>
              <div className={classes.squareTag} style={{ backgroundColor: navy }}/>
              <Typography className={classes.noteText}>Comfirmed</Typography>
            </Grid>
          )}
          {boostedRows?.length > 0 && (
            <Grid item className={classes.note}>
              <img
                src={`${IMG_STORAGE_LINK}dollar.svg`}
                style={{
                  width: 12,
                  height: 12
                }}
              />
              <Typography className={classes.noteText}>Boosted</Typography>
            </Grid>
          )}
        </Grid>
        <Typography style={{ fontSize: '12px' }}>
          <span style={{color:orange,fontWeight:700}}>4</span> - numbers indicate priority points
        </Typography>
      </Grid>
      {warningMsg && (<div className={classes.error}>
        <ErrorOutlineIcon fontSize='small' />
        <span style={{ marginLeft: '5px' }}>{warningMsg}</span>
      </div>)}
    </div>
  )

  const renderMobileAction = () => {
    return (
      <div className={classes.mButtonContainer} style={{ marginTop: isMobile ? 20 : 0, marginBottom: isMobileTop ? 20 : 0 }}>
        <Button variant='text' onClick={onCancel} disabled={requestedDates && requestedDates.length === 0} className={classes.mResetButton}>{cancelButtonText}</Button>
        {showShiftCalendar && <Button
          onClick={() => onShiftCalendarClick()}
          className={classes.mShowShiftCalendarBtn}
        > 
          Shift Calendar
        </Button>
        }
        { !isEmpty(boostDetails) && (
          <span className={classes.boostText} style={{ color: 'white' }}>{`${boostDetails.date && moment(boostDetails.date).format('MM/DD')} - $${boostDetails.amount||0}`}</span>
        )}
        <Button variant='text' onClick={onOk} className={classes.mSubmitButton}>{submitButtonText}</Button>
      </div>
    );
  }

  const renderDesktopAction = () => {
    return (
      <div className={classes.buttonContainer}>
        {showShiftCalendar && <Button
          onClick={() => onShiftCalendarClick()}
          className={classes.showShiftCalendarBtn}
        > 
          <EventIcon className={classes.takeShiftInfoIcon} />
          Shift Calendar
        </Button>
        }
      { !isEmpty(boostDetails) && (
        <span className={classes.boostText} style={{ marginRight: '16px', color: orange }}>{`${boostDetails.date && moment(boostDetails.date).format('MM/DD')} - $${boostDetails.amount||0}`}</span>
      )}
        <div>
          <Button onClick={onCancel} disabled={requestedDates && requestedDates.length === 0}
                  className={classes.resetButton}>
            {cancelButtonText}
          </Button>
          <Button onClick={onOk} className={classes.submitButton}>
            {submitButtonText}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center'>
        {isMobileTop && renderMobileAction()}
        {renderCalendar(displayDate, dateTimeFormatted)}
        {!isMobile && !isMobileTop && renderDesktopAction()}
        {isMobile && renderMobileAction()}
      </Grid>
    </div>
  )
}

export default withRouter(SingleCalendar);